<template>
  <div class="app">
    <div class="order-center">
      <div class="order-logo">
        <img src="../../assets/images/order_logo_unfinished.png" alt="">
      </div>
      <div class="unfinished-words">您有未完成订单，请先完成订单或取消订单</div>
      <div class="check-orders">
        <Button class="login_buttonBg unfinished-button"
                style="width: 320px;height: 57px;margin-top: 40px;"
                type="primary" @click="returnOrders">
          查看未完成订单
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "unfinishedOrder",
    created() {

    },
    mounted() {

    },
    methods: {
      returnOrders() {
        this.$router.push({name:"myBill"});
      },
    }
  }
</script>

<style scoped lang="stylus">
  .order-center {
    width: 740px;
    margin: 100px auto;
    .order-logo {
      margin-left: 290px;
      transform: translateY(40%);
    }

    .unfinished-words {
      margin-top: 90px;
      margin-left: 65px;
      font-size: 32px;
      font-weight: 800;
      color: rgba(67, 67, 67, 1);
      line-height: 48px;
    }

    .check-orders {
      margin-left: 210px;

      .unfinished-button {
        font-size: 24px;
        font-weight: 800;
        color: rgba(255, 255, 255, 1);
        line-height: 36px;
      }
    }
  }
</style>
